import Fuse from "fuse.js";

type TeacherFilters = {
  query?: string;
  public?: boolean;
};

export const useTeacherList = (key: string, filters?: TeacherFilters) => {
  const client = useDatabaseClient();
  const items = ref<DatabaseTable<"teachers">[]>([]);
  let fuseInstance: Fuse<DatabaseTable<"teachers">>;

  const response = useAsyncData(
    key,
    async () => {
      let query = client
        .from("teacher_with_companies")
        .select("*, user: users(*), courses(title, id), tags(*)");

      if (filters?.public !== undefined && filters?.public !== null) {
        query = query.eq("public", filters.public);
      }

      const { data } = await query.order("full_name").returns<
        Array<
          DatabaseTable<"teachers"> & {
            companies: DatabaseTable<"companies">;
            user: DatabaseTable<"users">;
            courses: Pick<DatabaseTable<"courses">, "title" | "id">[];
            tags: DatabaseTable<"tags">[];
          }
        >
      >();

      return data;
    },
    {
      watch: [computed(() => filters?.public)],
    },
  );

  whenever(response.data, (value) => {
    items.value = value ?? [];
    fuseInstance = new Fuse(items.value, {
      keys: ["full_name", "job"],
      threshold: 0.3,
    });
  });

  watch([() => filters?.query], () => {
    if (!fuseInstance) return;

    items.value = filters?.query
      ? fuseInstance.search(filters?.query).map((result) => result.item)
      : (response.data.value ?? []);
  });

  return {
    pending: response.pending,
    items,
  };
};
